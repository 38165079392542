<template>
  <!-- BEGIN: Dark Mode Switcher-->
  <div
    class="dark-mode-switcher cursor-pointer shadow-md fixed bottom-0  box dark:bg-dark-2 border rounded-full w-40 h-12 flex items-center justify-center z-50 mb-10 "
    :class="isRTL ? 'left-0 ml-10' : 'right-0 mr-10'"
    @click="switchMode"
  >
    <div class="mr-4 text-gray-700 dark:text-gray-300 ml-2">{{i18n('common.darkMode')}}</div>
    <div
      :class="{ 'dark-mode-switcher__toggle--active': darkMode }"
      class="dark-mode-switcher__toggle border"
    ></div>
  </div>
  <!-- END: Dark Mode Switcher-->
</template>

<script>
import { defineComponent, onMounted, computed } from 'vue'
import { useStore } from '@/store'

export default defineComponent({
  computed: {
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    }
  },
  setup() {
    const store = useStore()
    const darkMode = computed(() => store.state.main.darkMode)

    const setDarkModeClass = () => {
      darkMode.value
        ? cash('html').addClass('dark')
        : cash('html').removeClass('dark')
    }

    const switchMode = () => {
      store.dispatch('main/setDarkMode', !darkMode.value)
      setDarkModeClass()
    }

    onMounted(() => {
      setDarkModeClass()
    })

    return {
      switchMode,
      darkMode
    }
  }
})
</script>
