<template>
  <!-- BEGIN: Top Bar -->
  <div
    class="top-bar-boxed border-b border-theme-2 -mx-3 sm:-mx-8 px-3 sm:px-8 lg:pt-0 mb-12"
  >
    <div class="h-full flex items-center">
      <!-- BEGIN: Logo -->
      <div>
        <a href="" class="-intro-x hidden lg:flex">
          <img src="/images/png/public/smouha-logo.svg" style="width: 4rem" />
          <div>
            <span class="items-center text-white my-auto text-2xl font-bold"
              >{{ i18n('app.smouha')
              }}<span class="ml-1" style="color: #d42428">
                {{ i18n('app.sc') }}</span
              ></span
            >
            <p class="text-white">{{ i18n('app.dashboard') }}</p>
            <!-- {{currentUser}} -->
          </div>
        </a>
      </div>

      <!-- END: Logo -->
      <!-- BEGIN: Breadcrumb -->
      <div
        class="-intro-x breadcrumb"
        :class="{
          'ml-auto': isRTL,
          'mr-auto': !isRTL
        }"
      >
        <a href="">{{ i18n('breadcrumbs.application') }}</a>
        <ChevronRightIcon class="breadcrumb__icon" v-if="!isRTL" />
        <ChevronLeftIcon class="breadcrumb__icon" v-else />
        <a href="" class="breadcrumb--active dark:text-gray-400">{{
          i18n('breadcrumbs.dashboard')
        }}</a>
      </div>
      <!-- END: Breadcrumb -->
      <div>
        <i18nFlags class="i18nFlags" />
      </div>
      <!-- BEGIN: Search -->
      <!-- <div class="intro-x relative mr-3 sm:mr-6">
        <div class="dropdown sm:hidden">
          <button class="dropdown-toggle " aria-expanded="false">
            <SearchIcon />
          </button>
          <div class="dropdown-menu">
            <div
              class="dropdown-menu__content searchDropdown box dark:bg-dark-1 p-3"
            >
              <h2>{{ i18n('topbar.search.pages') }}</h2>
              <div class="flex mt-5">
                <div
                  class="w-10 h-10 bg-theme-29 text-theme-10 flex items-center justify-center rounded-full"
                  @click="goToAdmins()"
                >
                <UsersIcon class="w-4 h-4" />
                </div>
                <div class="ml-3 mr-3 mt-2">
                  {{ i18n('topbar.search.admins') }}
                </div>
              </div>
              <div class="flex mt-2">
                <div
                  class="w-10 h-10 bg-theme-30 text-theme-24 flex items-center justify-center rounded-full"
                  @click="goToArticles()"
                >
                <FileTextIcon class="w-4 h-4" />
                </div>
                <div class="ml-3 mr-3 mt-2">
                  {{ i18n('topbar.search.articles') }}
                </div>
              </div>
              <div class="flex mt-2">
                <div
                  class="w-10 h-10 bg-theme-31 text-theme-26 flex items-center justify-center rounded-full"
                  @click="goToMedia()"
                >
                  <ImageIcon class="" />
                </div>
                <div class="ml-3 mr-3 mt-2">
                  {{ i18n('topbar.search.media') }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="search hidden sm:block">
          <input
            type="text"
            class="search__input form-control dark:bg-dark-3 border-transparent placeholder-theme-8 dark:text-white"
            @focus="showSearchDropdown"
            @blur="hideSearchDropdown"
          />

          <SearchIcon class="search__icon dark:text-gray-300" />
          <div
            id="search-popup"
            class="search-result"
            :class="{ show: searchDropdown }"
          >
            <div class="search-result__content">
              <div class="search-result__content__title">
                {{ i18n('topbar.search.pages') }}
              </div>
              <div class="mb-5">
                <div
                  class="flex items-center cursor-pointer"
                  @click="goToAdmins()"
                >
                  <div
                    class="w-8 h-8 bg-theme-29 text-theme-10 flex items-center justify-center rounded-full"
                  >
                    <UsersIcon class="w-4 h-4" />
                  </div>
                  <div class="ml-3 mr-3">{{ i18n('topbar.search.admins') }}</div>
                </div>
                <div
                  class="flex items-center mt-2 cursor-pointer"
                  @click="goToArticles()"
                >
                  <div
                    class="w-8 h-8 bg-theme-30 text-theme-24 flex items-center justify-center rounded-full"
                  >
                    <FileTextIcon class="w-4 h-4" />
                  </div>
                  <div class="ml-3 mr-3">{{ i18n('topbar.search.articles') }}</div>
                </div>
                <div
                  class="flex items-center mt-2 cursor-pointer"
                  @click="goToMedia()"
                >
                  <div
                    class="w-8 h-8 bg-theme-31 text-theme-26 flex items-center justify-center rounded-full"
                  >
                    <ImageIcon class="w-4 h-4" />
                  </div>
                  <div class="ml-3 mr-3">
                    {{ i18n('topbar.search.media') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!-- END: Search -->
      <!-- BEGIN: Notifications -->

      <!-- <div class="dropdown">
        <button class="dropdown-toggle " aria-expanded="false">
          <div
            class="dropdown-toggle notification notification--bullet cursor-pointer mr-4"
            role="button"
            aria-expanded="false"
          >
            <BellIcon class="notification__icon dark:text-gray-300" />
          </div>
        </button>
        <div class="dropdown-menu">
          <div
            class="dropdown-menu__content notificationDropDown box dark:bg-dark-1 p-3"
          >
            <div class="notification-content__title mb-2">
              {{ i18n('topbar.notifications.title') }}
            </div>
            <div
              v-for="(faker, fakerKey) in $_.take($f(), 5)"
              :key="fakerKey"
              class="cursor-pointer relative flex items-center"
              :class="{ 'mt-5': fakerKey }"
            >
              <div class="w-12 h-12 flex-none image-fit mr-1">
                <img
                  alt="Icewall Tailwind HTML Admin Template"
                  class="rounded-full"
                  :src="require(`@/assets/images/${faker.photos[0]}`)"
                />
                <div
                  class="w-3 h-3 bg-theme-10 absolute right-0 bottom-0 rounded-full border-2 border-white"
                ></div>
              </div>
              <div class="ml-2 overflow-hidden">
                <div class="flex items-center">
                  <a
                    href="javascript:;"
                    class="font-medium truncate "
                    :class="isRTL ? 'mr-2 ml-10' : 'mr-5'"
                  >
                    Ahmed Mohamed
                  </a>
                  <div class="text-xs text-gray-500 ml-auto whitespace-nowrap">
                    {{ i18n('topbar.notifications.time') }}
                  </div>
                </div>
                <div
                  class="w-full truncate text-gray-600 dark:text-gray-400 mt-0.5 mr-2"
                >
                  {{ i18n('topbar.notifications.description') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!-- END: Notifications -->
      <!-- BEGIN: Account Menu -->

      <div class="dropdown" style="margin-top: 6.5px">
        <button class="dropdown-toggle" aria-expanded="false">
          <div
            class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in scale-110 mr-4 ml-4"
            role="button"
            aria-expanded="false"
            @click="showAccountDropDown = true"
          >
            <img
              :src="currentUserAvatar"
              style="border-radius: 50%; height: 100%"
              v-if="currentUserAvatar"
            />
            <img
              :src="require(`@/assets/images/${$f()[9].photos[0]}`)"
              alt="Icewall Tailwind HTML Admin Template"
              v-else
            />
          </div>
        </button>
        <div class="dropdown-menu" v-if="showAccountDropDown">
          <div
            class="dropdown-menu__content profileDropDown box bg-theme-11 dark:bg-dark-6 text-white"
          >
            <div class="p-4 border-b border-theme-12 dark:border-dark-3">
              <div class="font-medium">{{ currentUserNameOrEmailPrefix }}</div>
            </div>
            <div class="p-2">
              <div
                class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md cursor-pointer"
                @click="goToProfile()"
              >
                <UserIcon class="w-4 h-4" :class="isRTL ? 'ml-2' : 'mr-2'" />
                {{ i18n('topbar.profile.profile') }}
              </div>
            </div>
            <div class="p-2 border-t border-theme-12 dark:border-dark-3">
              <div
                href=""
                class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md cursor-pointer dropdown-toggle"
                @click="doLogout()"
              >
                <ToggleRightIcon
                  class="w-4 h-4"
                  :class="isRTL ? 'ml-2' : 'mr-2'"
                />
                {{ i18n('topbar.profile.logout') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Account Menu -->
    </div>
  </div>
  <!-- END: Top Bar -->
</template>

<script>
import { defineComponent, ref } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import i18nFlags from '@/components/i18n/i18n-flags.vue'
// import VueLottiePlayer from 'vue-lottie-player'

export default defineComponent({
  components: {
    i18nFlags
  },
  computed: {
    ...mapGetters({
      currentUserNameOrEmailPrefix: 'auth/currentUserNameOrEmailPrefix',
      currentUserAvatar: 'auth/currentUserAvatar',
      currentUser: 'auth/currentUser',

      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop'
      // currentLanguageCode: 'layout/currentLanguageCode',
    }),
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },
  setup() {
    const searchDropdown = ref(false)
    const showAccountDropDown = ref(false)
    const showSearchDropdown = () => {
      searchDropdown.value = true
    }

    const hideSearchDropdown = () => {
      searchDropdown.value = false
    }

    return {
      showAccountDropDown,
      searchDropdown,
      showSearchDropdown,
      hideSearchDropdown
    }
  },
  methods: {
    ...mapActions({
      toggleMenu: 'layout/toggleMenu',
      doSignout: 'auth/doSignout'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    goToProfile() {
      console.log('profile')
      this.$router.push('/profile')
    },
    goToResetPassword() {
      console.log('goToResetPassword')
      // this.$router.push('/home/profile')
    },
    doLogout() {
      this.showAccountDropDown = false
      this.doSignout()
      // this.$router.push('/auth/login')
    },
    goToAdmins() {
      this.$router.push('/admins')
    },
    goToArticles() {
      this.$router.push('/article')
    },
    goToMedia() {
      this.$router.push('/media')
    }
  }
})
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Bakbak+One&display=swap');

// .i18nFlags {
//   @media only screen and (max-width: 425px) {
//     display: none;
//   }
// }

.searchDropdown {
  width: 200px !important;
  position: absolute !important;
  right: 0 !important;
}

[dir='rtl'] .searchDropdown {
  right: unset !important;
  left: 0 !important;
}

.notificationDropDown {
  width: 300px !important;
  position: absolute !important;
  right: 0 !important;
}

[dir='rtl'] .notificationDropDown {
  right: unset !important;
  left: 0 !important;
}

.profileDropDown {
  width: 200px !important;
  position: absolute !important;
  right: 0 !important;
}

[dir='rtl'] .profileDropDown {
  right: unset !important;
  left: -20px !important;
}
</style>
