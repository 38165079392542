<template>
  <div>
    <!-- <DarkModeSwitcher /> -->
    <MobileMenu />
    <TopBar />
    <!-- BEGIN: Top Menu -->
    <nav class="top-nav">
      <ul>
        <!-- BEGIN: First Child -->
        <li v-for="(menu, menuKey) in formattedMenu" :key="menuKey">
          <a v-if="currentUser.roles.includes('owner')" :href="
            menu.subMenu
              ? 'javascript:;'
              : router.resolve({ name: menu.pageName }).path
          " class="top-menu" :class="{
  'top-menu--active': menu.active
}" @click="linkTo(menu, router, $event)">
            <div class="top-menu__icon">
              <span v-if="menu.icon == 'ChefIcon'" class="material-icons-outlined mt-1"
                :style="darkMode ? 'color: #828d9b' : 'color: #c3c2d8'">
                restaurant
              </span>
              <span v-if="menu.icon == 'SportsIcon'" class="material-icons-outlined mt-1"
                :style="darkMode ? 'color: #828d9b' : 'color: #c3c2d8'">
                sports_soccer
              </span>
              <img v-if="menu.icon == 'administration'" src="@/assets/images/administration1.png" class="w-9 h-9">
              <img v-if="menu.icon == 'logs'" src="@/assets/images/archive-3.svg" class="w-7 h-7">
              <component :is="menu.icon" class="w-7 h-7" />
            </div>
            <div class="top-menu__title mr-1">
              {{ i18n(`menu.${menu.title}`) }}
              <ChevronDownIcon v-if="menu.subMenu" class="top-menu__sub-icon" />
            </div>
          </a>

          <a v-else-if="menu.pageName != 'admins' && menu.pageName != 'logs'" :href="
            menu.subMenu
              ? 'javascript:;'
              : router.resolve({ name: menu.pageName }).path
          " class="top-menu" :class="{
  'top-menu--active': menu.active
}" @click="linkTo(menu, router, $event)">
            <div class="top-menu__icon">
              <span v-if="menu.icon == 'ChefIcon'" class="material-icons-outlined mt-1"
                :style="darkMode ? 'color: #828d9b' : 'color: #c3c2d8'">
                restaurant
              </span>
              <span v-if="menu.icon == 'SportsIcon'" class="material-icons-outlined mt-1"
                :style="darkMode ? 'color: #828d9b' : 'color: #c3c2d8'">
                sports_soccer
              </span>
              <img v-if="menu.icon == 'administration'" src="@/assets/images/administration1.png" class="w-9 h-9">
              <img v-if="menu.icon == 'logs'" src="@/assets/images/archive-3.svg" class="w-7 h-7">
              <component :is="menu.icon" class="w-7 h-7" />
            </div>
            <div class="top-menu__title mr-1">
              {{ i18n(`menu.${menu.title}`) }}
              <ChevronDownIcon v-if="menu.subMenu" class="top-menu__sub-icon" />
            </div>
          </a>
          <!-- BEGIN: Second Child -->
          <ul v-if="menu.subMenu">
            <li v-for="(subMenu, subMenuKey) in menu.subMenu" :key="subMenuKey">
              <a :href="
                subMenu.subMenu
                  ? 'javascript:;'
                  : router.resolve({ name: subMenu.pageName }).path
              " class="top-menu" @click="linkTo(subMenu, router, $event)">
                <div class="top-menu__icon">
                  <ActivityIcon />
                </div>
                <div class="top-menu__title">
                  {{ subMenu.title }}
                  <ChevronDownIcon v-if="subMenu.subMenu" class="top-menu__sub-icon" />
                </div>
              </a>
              <!-- BEGIN: Third Child -->
              <ul v-if="subMenu.subMenu">
                <li v-for="(lastSubMenu, lastSubMenuKey) in subMenu.subMenu" :key="lastSubMenuKey">
                  <a :href="
                    lastSubMenu.subMenu
                      ? 'javascript:;'
                      : router.resolve({
                        name: lastSubMenu.pageName
                      }).path
                  " class="top-menu" @click="linkTo(lastSubMenu, router, $event)">
                    <div class="top-menu__icon">
                      <component :is="'zap-icon'" />
                    </div>
                    <div class="top-menu__title">
                      {{ lastSubMenu.title }}
                    </div>
                  </a>
                </li>
              </ul>
              <!-- END: Third Child -->
            </li>
          </ul>
          <!-- END: Second Child -->
        </li>
        <!-- END: First Child -->
      </ul>
    </nav>
    <!-- END: Top Menu -->
    <!-- BEGIN: Content -->
    <div class="wrapper wrapper--top-nav">
      <div class="wrapper-box">
        <!-- BEGIN: Content -->
        <div class="content">
          <router-view />
        </div>
        <!-- END: Content -->
      </div>
    </div>
    <!-- END: Content -->
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store'
import { helper as $h } from '@/utils/helper'
import TopBar from '@/components/top-bar/Main.vue'
import MobileMenu from '@/components/mobile-menu/Main.vue'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import SideMenuTooltip from '@/components/side-menu-tooltip/Main.vue'
import { linkTo, nestedMenu, enter, leave } from './index'
import { mapGetters } from 'vuex'

export default defineComponent({
  components: {
    TopBar,
    MobileMenu,
    DarkModeSwitcher,
    SideMenuTooltip
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const darkMode = computed(() => store.state.main.darkMode)
    const formattedMenu = ref([])
    const sideMenu = computed(() =>
      nestedMenu(store.state.sideMenu.menu, route)
    )

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(sideMenu.value)
      }
    )

    onMounted(() => {
      cash('body')
        .removeClass('error-page')
        .removeClass('login')
        .removeClass('login-rtl')
        .addClass('main')
      formattedMenu.value = $h.toRaw(sideMenu.value)
    })

    return {
      formattedMenu,
      darkMode,
      router,
      linkTo,
      enter,
      leave
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  }
})
</script>

<style lang="scss">
.runProf {
  position: absolute;
  // margin-bottom: auto !important;
  bottom: 5%;
  left: 4%;

  @media only screen and (max-width: 1024px) {
    width: 80px;
    left: 1%;
    // display: none;
  }
}

[dir='rtl'] .runProf {
  position: absolute;
  bottom: 5%;
  left: 91% !important;

  @media only screen and (max-width: 1024px) {
    width: 80px;
    left: 90% !important;
    // display: none;
  }

  @media only screen and (max-width: 768px) {
    width: 80px;
    left: 87% !important;
    // display: none;
  }
}
</style>
